import React from "react";
import { Col, Row, Image } from "react-bootstrap";
import analyticscolimg from "../../../assets/images/analytics-col-img.png";

const Analytics = () => {
    return(
        <div className="analytics">
            <div className="container-width">
                <Row>
                    <Col lg="6" className="d-flex align-items-center order-lg-first order-last">
                        <div className="analytics-content">
                            <h6>Analytics</h6>
                            <h3>Song Analytics as Per Rank</h3>
                            <p>MatchRanker is a versatile platform powered by a global community of artists and listeners on the pulse of what’s new, now, and next in music culture. Sit back, relax, and manage your profile, universe, and playlists with ease. </p>
                            <ul className="m-0 p-0 analytics-list">
                                <li>Browse through music, friends, celebrities, and more.</li>
                                <li>Choose your favorite music, or let MatchRanker surprise you.</li>
                                <li>Listen to music repeatedly to rank and make matches. </li>
                                <li>Find new music and share it with your social networks. </li>
                            </ul>
                        </div>
                    </Col>
                    <Col lg="6" className="order-lg-last order-first mb-lg-0 mb-4">
                        <div className="analytics-col-img">
                            <Image src={analyticscolimg} fluid/>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    )
}
export default Analytics;
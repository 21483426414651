import React from "react";
import { Col, Row, Image } from "react-bootstrap";
import helpcenterimg from "../../../assets/images/help-center-img.png";

const HelpCenter = () => {
    return(
        <div className="help-center">
            <div className="container-width">
                <Row>
                    <Col lg="6">
                        <div className="help-center-img position-relative">
                            <Image src={helpcenterimg} fluid/>
                        </div>
                    </Col>
                    <Col lg="6" className="d-flex align-items-center">
                        <div className="help-center-content">
                            <h6>Help Center</h6>
                            <h3>24/7 Help Desk</h3>
                            <p>Feel free to contact our experts around the clock if you have any questions. Get up-to-date information about generating matches, ranking songs, song management, user interaction, user universe, and more.</p>
                            <ul className="m-0 p-0 help-center-list">
                                <li>Account Management Help</li>
                                <li>Activity Management Help</li>
                                <li>Song Management Help </li>
                                <li>Universe Management Help</li>
                                <li>General Performance help</li>
                                <li>Safety & Privacy</li>
                            </ul>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    )
}
export default HelpCenter;
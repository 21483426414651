import React from "react";
import { Col, Row, Image, Button } from "react-bootstrap";
import aboutcolimg from "../../../assets/images/about-us-col-img.png";

const About = () => {
    return(
        <div className="about-us position-relative">
            <div className="container-width">
                <Row className="align-items-center">
                    <Col lg="6" className="mb-lg-0 mb-4">
                        <div className="about-us-img">
                            <Image src={aboutcolimg} fluid/>
                        </div>
                    </Col>
                    <Col lg="6">
                        <div className="about-us-content text-lg-start text-center">
                            <h6>About us</h6>
                            <h3>MatchRanker App</h3>
                            <p>As the world’s most extensive music and audio platform, MatchRanker lets people discover and enjoy the most incredible selection of music from the most diverse creator community on earth. Since launching in 2023, the platform has become renowned for its unique content and features, including sharing music, matching songs, ranking songs, connecting directly with artists, unearthing breakthrough tracks, raw demos, podcasts, and more. This is made possible by an open platform that directly connects creators and their fans across the globe.  </p>
                            <p className="mb-lg-5 mb-4">Music and audio creators use MatchRanker to share and monetize their content with a global audience and receive detailed stats and feedback from the MatchRanker community.</p>
                            <Button className="btn-orange">Download App</Button>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    )
}
export default About;
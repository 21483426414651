import React from "react";
import { Image } from "react-bootstrap";
import featurestree from "../../../assets/images/features-img-tree.png";
import featuresstone from "../../../assets/images/features-stone-img.png";
import featuresmobiletree from "../../../assets/images/features-mobile-tree.png";

const Features = () => {
    return (
        <div className="features">
            <div className="container-width">
                <div className="features-heading text-center">
                    <h6>Key Features</h6>
                    <h3>Match and Rank Songs</h3>
                    <p className="mb-0">Choose what you want to listen to, or let MatchRanker surprise you by generating a random match. Rank your favorite songs by listening to them on repeat, and earn reward.You can also browse through the collections of friends, artists, and celebrities or create the universe and just sit back. So what are you waiting for? Download the app now and listen to music for free.</p>
                </div>
                <div className="features-img-wrapper text-center">
                    <div className="features-tree-img position-relative">
                        <Image src={featurestree} fluid/>
                    </div>
                    <div className="features-tree-mobile-img position-relative">
                        <Image src={featuresmobiletree} fluid/>
                    </div>
                    <div className="features-stone-img">
                        <Image src={featuresstone} fluid/>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Features;
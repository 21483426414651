import React from "react";

const TermsInfo = () => {
    return (
        <div className="section-heading-wrapper">
            <div className="container-width">
                <div className="section-heading text-center">
                    <h6>Terms of Use</h6>
                    <h3>MatchRanker Services Terms and Conditions</h3>
                    <p>These Terms of Use govern your use of MatchRanker (together, the "Website"), our mobile and desktop apps (our "Apps"), and all related players, widgets, tools, features, applications, data, software, APIs (which may also be subject to separate API Terms of Use) and other services provided by MatchRanker (the "Services").</p>
                </div>
            </div>
        </div>
    )
}
export default TermsInfo;
import React from "react";
import { Col, Row, Image } from "react-bootstrap";
import guideimgone from "../../../assets/images/guide-img-one.svg";
import guideimgtwo from "../../../assets/images/guide-img-two.svg";
import guideimgthree from "../../../assets/images/guide-img-three.svg";

const PrivacyGuide = () => {
    return(
        <div className="privacy-guide">
            <div className="container-width">
                <Row>
                    <Col lg="4" className="mb-lg-0 mb-4">
                        <div className="privacy-guide-content text-xl-start text-lg-start text-center">
                            <div className="privacy-guide-content-img mb-4">
                                <Image src={guideimgone} fluid/>
                            </div>
                            <h4>Secure Data Collection</h4>
                            <p className="mb-0">We ask for and collect your profile and account information when you use the platform under high-security protocols to dodge denial-of-service attacks. </p>
                        </div>
                    </Col>
                    <Col lg="4" className="mb-lg-0 mb-4">
                        <div className="privacy-guide-content text-xl-start text-lg-start text-center">
                            <div className="privacy-guide-content-img mb-4">
                                <Image src={guideimgtwo} fluid/>
                            </div>
                            <h4>Data Processing Laws</h4>
                            <p className="mb-0">Using contractual and actual safeguards, we ensure that adequate data protection is maintained (e.g., GDPR, Standard Contractual Clauses, or CCPA).</p>
                        </div>
                    </Col>
                    <Col lg="4" className="mb-lg-0 mb-4">
                        <div className="privacy-guide-content text-xl-start text-lg-start text-center">
                            <div className="privacy-guide-content-img mb-4">
                                <Image src={guideimgthree} fluid/>
                            </div>
                            <h4>Information Security</h4>
                            <p className="mb-0">We maintain appropriate physical, technical, and administrative safeguards to prevent unauthorized access, possible vulnerabilities, and attacks.</p>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    )
}
export default PrivacyGuide;
import React from "react";

const PrivacyInfo = () => {
    return (
        <div className="section-heading-wrapper">
            <div className="container-width">
                <div className="section-heading text-center">
                    <h6>MatchRanker Privacy Policy</h6>
                    <h3>Your Privacy is Important to Us</h3>
                    <p>This Privacy Policy explains how we collect, store, use, disclose and otherwise process your data when you use matchranker.com (together, the “Website”), our mobile and desktop apps (the “Apps”), and all related sites, players, widgets, tools, data, software, APIs and other services provided by MatchRanker (the “Services”). This Privacy Policy applies to any Website, Apps, or Services by MatchRanker that refers to this Privacy Policy, i.e., by linking to it. From time to time, we may develop new or offer additional services. They'll also be subject to this Policy unless stated otherwise when we introduce them.</p>
                </div>
            </div>
        </div>
    )
}
export default PrivacyInfo;
import React from "react";
import { Col, Row } from "react-bootstrap";

const MatchRankerApp = () => {
    return (
        <div className="match-ranker-app text-lg-start text-center">
            <div className="container-width">
                <Row className="align-items-center">
                    <Col lg="4">
                        <div className="match-ranker-app-content">
                            <h3>Thanks For Using <span>MatchRanker App</span></h3>
                        </div>
                    </Col>
                    <Col lg="8">
                        <div className="match-ranker-app-content">
                            <p className="mb-3">These Terms of Use, together with our Community Guidelines and any other terms specifically referred to in any of those documents, all of which are incorporated by reference into these Terms of Use, constitute a legally binding contract (the "Agreement") between you and MatchRanker in relation to your use of the Website, Apps and Services (together, the "Platform").</p>
                            <p>Please also be sure to review our Privacy Policy and Cookies Policy for more information on how we collect and use data relating to the use and performance of the Platform, as well as our responsibilities and your rights in relation to any processing of your data.</p>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    )
}
export default MatchRankerApp;
import React from "react";
import { Accordion, Button, Col, Form, Row, Image } from "react-bootstrap";
import usericon from "../../../assets/images/user-icon.svg";
import mailicon from "../../../assets/images/mail-icon.svg";
import callicon from "../../../assets/images/call-icon.svg";

const FAQsInfo = () => {
    // const accordionfaq = [
    //     {
    //         id: '01',
    //         accordionHeader: "What is MatchRanker?",
    //         accordionBody: "As the world’s most extensive music and audio platform, MatchRanker lets people discover and enjoy the most incredible selection of music from the most diverse creator community on earth. Since launching in 2023, the platform has become renowned for its unique content and features, including sharing music, matching songs, ranking songs, connecting directly with artists, unearthing breakthrough tracks, raw demos, podcasts, and more. This is made possible by an open platform that directly connects creators and their fans across the globe. Music and audio creators use MatchRanker to share and monetize their content with a global audience and receive detailed stats and feedback from the MatchRanker community.",
    //     },
    //     {
    //         id: '02',
    //         accordionHeader: "What should I do if I forget my password?",
    //         accordionBody: "If you don’t remember your password, you can reset it. To do so:",
    //     },
    //     {
    //         id: '03',
    //         accordionHeader: "How do I get started with MatchRanker?",
    //         accordionBody: "Now that you are a part of the MatchRanker community, here are some basics to start you off:",
    //     },
    //     {
    //         id: '04',
    //         accordionHeader: "How do I rank a song?",
    //         accordionBody: "In order to rank a song, you have to listen to it repeatedly. Songs are listed in descending order, i.e., top-ranked songs appear on the top of the list. You can also earn rewards based on song ranking.",
    //     },
    //     {
    //         id: '05',
    //         accordionHeader: "How to generate matches?",
    //         accordionBody: "The system automatically generates matches based on the songs you prefer to hear. Related tracks are selected by an algorithm that returns recommendations through a network of relations and interactions on MatchRanker (for example, a user liked a track, a user followed another user, a track is reposted, etc.). The recommendations for a track can be directly or indirectly related to tracks in this network.",
    //     },
    //     {
    //         id: '06',
    //         accordionHeader: "How to report a spam account?",
    //         accordionBody: "We're continually fighting against spam, but sometimes we need help to catch it all because technology is evolving parallel to cybersecurity vulnerabilities.With your help, we'll have a better chance! You can Report any account by clicking on the report button you suspect to be a fake or spam account. This will also block that account from interacting with you and remove previous comments, like, or reposts made on your content.",
    //     },
    // ]
    return (
        <div className="faqs-info">
            <div className="container-width">
                <Row>
                    <Col lg="7" className="mb-lg-0 mb-5">
                        <div className="faqs-accordion">
                            <h3>Effective FAQ's</h3>
                            <Accordion defaultActiveKey={0}>
                                {/* {accordionfaq.map((accfaq, index) => (
                                    <Accordion.Item eventKey={index}>
                                        <Accordion.Header><span>{accfaq.id}</span>{accfaq.accordionHeader}</Accordion.Header>
                                        <Accordion.Body><p>{accfaq.accordionBody}</p>
                                        <ul>
                                            <li>On iPhone and Android, click on Forgot your password? at the sign-in screen</li>
                                            <li>On the web, go to the Forgot password page</li>
                                        </ul>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                ))} */}

                                <Accordion.Item eventKey="0">
                                    <Accordion.Header><span>01</span>What is MatchRanker?</Accordion.Header>
                                    <Accordion.Body><p>As the world’s most extensive music and audio platform, MatchRanker lets people discover and enjoy the most incredible selection of music from the most diverse creator community on earth. Since launching in 2023, the platform has become renowned for its unique content and features, including sharing music, matching songs, ranking songs, connecting directly with artists, unearthing breakthrough tracks, raw demos, podcasts, and more. This is made possible by an open platform that directly connects creators and their fans across the globe. Music and audio creators use MatchRanker to share and monetize their content with a global audience and receive detailed stats and feedback from the MatchRanker community.</p>

                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header><span>02</span>What should I do if I forget my password? </Accordion.Header>
                                    <Accordion.Body><p>If you don’t remember your password, you can reset it. To do so:</p>
                                        <ul>
                                            <li>On iPhone and Android, click on Forgot your password? at the sign-in screen</li>
                                            <li>On the web, go to the Forgot password page</li>
                                        </ul>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="2">
                                    <Accordion.Header><span>03</span>How do I get started with MatchRanker?</Accordion.Header>
                                    <Accordion.Body><p>Now that you are a part of the MatchRanker community, here are some basics to start you off:</p>
                                        <ul>
                                            <li>On iPhone and Android, download the MatchRanker app and signup by entering your valid information. </li>
                                            <li>On the web, go to the MatchRanker website and signup by entering your valid information. </li>
                                            <li>You can customize by profile by changing your profile image and profile description and making it more personal.</li>
                                            <li>Once you have set up your profile, you can start listening, commenting, liking, sharing, creating playlists, browsing, and more.</li>
                                        </ul>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="3">
                                    <Accordion.Header><span>04</span>How do I rank a song?</Accordion.Header>
                                    <Accordion.Body><p>In order to rank a song, you have to listen to it repeatedly. Songs are listed in descending order, i.e., top-ranked songs appear on the top of the list. You can also earn rewards based on song ranking. </p>

                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="4">
                                    <Accordion.Header><span>05</span>How to generate matches?</Accordion.Header>
                                    <Accordion.Body><p>The system automatically generates matches based on the songs you prefer to hear. Related tracks are selected by an algorithm that returns recommendations through a network of relations and interactions on MatchRanker (for example, a user liked a track, a user followed another user, a track is reposted, etc.). The recommendations for a track can be directly or indirectly related to tracks in this network.</p>

                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="5">
                                    <Accordion.Header><span>06</span>How to report a spam account?</Accordion.Header>
                                    <Accordion.Body><p>We're continually fighting against spam, but sometimes we need help to catch it all because technology is evolving parallel to cybersecurity vulnerabilities. </p>
                                        <p>
                                            With your help, we'll have a better chance!</p><p>
                                            You can "Report" any account by clicking on the report button you suspect to be a fake or spam account. This will also block that account from interacting with you and remove previous comments, like, or reposts made on your content.
                                        </p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                
                            </Accordion>
                        </div>
                    </Col>
                    <Col lg="5">
                        <div className="faqs-form">
                            <h3>Have a Question?</h3>
                            <Form>
                                <Form.Group className="input-wrapper d-flex align-items-center" controlId="formBasicEmail">
                                    <div className="user-icon-img me-3">
                                        <Image src={usericon} fluid />
                                    </div>
                                    <Form.Control type="text" placeholder="Enter your name" />
                                </Form.Group>
                                <Form.Group className="input-wrapper d-flex align-items-center" controlId="formBasicPassword">
                                    <div className="user-icon-img me-3">
                                        <Image src={mailicon} fluid />
                                    </div>
                                    <Form.Control type="email" placeholder="Enter your Email" />
                                </Form.Group>
                                <Form.Group className="input-wrapper d-flex align-items-center" controlId="formBasicPassword">
                                    <div className="user-icon-img me-3">
                                        <Image src={callicon} fluid />
                                    </div>
                                    <Form.Control type="text" placeholder="Enter your Phone Number" />
                                </Form.Group>
                                <Form.Group className="input-wrapper mb-5" controlId="formBasicPassword">
                                    <Form.Control as="textarea" rows={5} type="text" placeholder="Your Question" />
                                </Form.Group>
                                <div className="text-end">
                                    <Button className="btn-orange" type="submit">
                                        Submit
                                    </Button>
                                </div>
                            </Form>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    )
}
export default FAQsInfo;
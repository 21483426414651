import React from "react";
import { Col, Row, Image } from "react-bootstrap";
import termslistone from "../../../assets/images/terms-list-one.svg";
import termslisttwo from "../../../assets/images/terms-list-two.svg";
import termslistthree from "../../../assets/images/terms-list-three.svg";
import termslistfour from "../../../assets/images/terms-list-four.svg";
import termslistfive from "../../../assets/images/terms-list-five.svg";
import termslistsix from "../../../assets/images/terms-list-six.svg";

const TermsListing = () => {
    const termslisting = [
        {
            id: 1,
            image: termslistone,
            termsListContentHeading: "Acceptance of Terms of Use:",
            termsListContentPara: "By using MatchRanker, you accept our Terms of Use and Community Guidelines and agree to abide by them.",
        },
        {
            id: 2,
            image: termslisttwo,
            termsListContentHeading: "Changes to Terms of Use",
            termsListContentPara: "This section explains that our terms of Use may change from time to time.",
        },
        {
            id: 3,
            image: termslistthree,
            termsListContentHeading: "Description of the Platform",
            termsListContentPara: "This provides a general description of the Platform, its features, and its functionality.",
        },
        {
            id: 4,
            image: termslistfour,
            termsListContentHeading: "Your MatchRanker Account",
            termsListContentPara: "This section explains your responsibilities should you register for a MatchRanker account.",
        },
        {
            id: 5,
            image: termslistfive,
            termsListContentHeading: "Your Use of the Platform",
            termsListContentPara: "This section sets out your right to use the Platform and the conditions that apply to your use of the Platform.",
        },
        {
            id: 6,
            image: termslistsix,
            termsListContentHeading: "Your Content",
            termsListContentPara: "This section deals with ownership of your content and includes your agreement not to upload anything that infringes on anyone else’s rights.            ",
        },
    ]
    return (
        <div className="terms-listing">
            <div className="container-width">
                <Row>
                    {termslisting.map((trmlist) => (
                        <Col lg="6" className="mb-lg-5 mb-4">
                            <div className="terms-listing-content d-flex justify-content-between flex-lg-row flex-column">
                                <div className="terms-listing-content-img d-flex align-items-center justify-content-center">
                                    <Image src={trmlist.image} fluid />
                                </div>
                                <div className="terms-listing-content-info">
                                    <h5 className="mb-xxl-4 mb-lg-3">{trmlist.termsListContentHeading}</h5>
                                    <p>{trmlist.termsListContentPara}</p>
                                </div>
                            </div>
                        </Col>
                    ))}
                </Row>
            </div>
        </div>
    )
}
export default TermsListing;